/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


if (typeof(Ecwid) === 'object') {
  Ecwid.OnAPILoaded.add(function() {
    // Redirect address. Change it to the URL of page where you want to redirect your customers.
    // You can use absolute or relative addresses, e.g. 'index.html', 'http://google.com'
    var continueShoppingRedirect = "https://numode.net";
    // Delay (ms), which is necessary for the empty cart page to appear after onCartChange event firing
    var empty_cart_page_delay = 500;
    // Continue shopping buttons CSS selectors
    // (you can remove the ones that you don't want to change behavior for)
    var buttons = [
      '.ecwid-btn--continueShopping', // Cart page, Order confirmation page and Search results page
      'div.ecwid-productBrowser-cart-emptyCartPanel .ecwid-btn--secondary', // Empty cart page
    ];
    // Pages (Ecwid.Page.type) where buttons should be customized
    // (you can remove the pages that you don't want to change the buttons on)
    var pages = [
        'CART',
        'SEARCH',
        'ORDER_CONFIRMATION',
        'ACCOUNT_SETTINGS',
        'ORDERS',
        'ADDRESS_BOOK'
    ];
    // This function find the continue shopping button on the page and replace it with a customized one
    var replaceButton = function() {
      var buttonObject = jQuery(buttons.join()).filter(":not('.clone'):visible");
      if (buttonObject.length) {
        buttonObject.clone().addClass('clone').appendTo(buttonObject.parent()).click(function() {
          location.href = continueShoppingRedirect;
        });
        // Remove the original button
        buttonObject.remove();
      }
    };
    // Replace the button on page loading
    Ecwid.OnPageLoaded.add(function(page) {
      if (jQuery.inArray(page.type, pages) >= 0) {
        replaceButton();
      }
    });
    // Replace the button on the empty cart page after clearing the cart
    // (it doesn't fire onPageLoaded event)
    Ecwid.OnCartChanged.add(function(page) {
      setTimeout(replaceButton, empty_cart_page_delay);
    });
  });
}
